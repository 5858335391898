import { EditorReadyFn, } from 'yoshi-flow-editor-runtime';
import { EditorScriptFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/EditorScript';
import { BM, BM_CREATE_ALBUM, BM_MANAGE_ALBUMS_STORE } from './consts/routes';
import { EditorSDK, EditorReadyOptions } from '@wix/platform-editor-sdk/lib';
import { checkIfHasBusinessPackage } from './services/gogApi';
import _ from 'lodash';

let appDefinitionId: string;
let sdk: EditorSDK;
let t: any;
let instance: string;
let hasBusinessPackage: boolean = false;

const CREATE_ALBUM_ACTION = 'openCreateAlbum';
const MANAGE_ALBUMS_ACTION = 'openAlbumsBusinessManager';
const MANAGE_ALBUMS_STORE_ACTION = 'openAlbumsStoreBusinessManager';
const CUSTOMIZE_ALBUMS_PAGE_ACTION = 'openPhotoAlbumsSettings';

export const editorReady: EditorReadyFn = async (
  _editorSDK: EditorSDK,
  _appDefinitionId: string,
  platformOptions: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
) => {
  const { reportError, fedopsLogger, translations } = flowAPI;
  appDefinitionId = _appDefinitionId;
  sdk = _editorSDK;
  t = translations.t;
  const locale = await sdk.environment.getLocale(appDefinitionId);
  const metaSiteId = await sdk.info.getMetaSiteId(appDefinitionId);
  instance = await sdk.document.info.getAppInstance(appDefinitionId);

  const data = await checkIfHasBusinessPackage(metaSiteId, locale);
  hasBusinessPackage = data ? data.result : false;

  fedopsLogger.appLoadStarted();
  try {
    if (platformOptions.firstInstall) {
      fedopsLogger.interactionStarted('install');
      // Place for your app initialization here. (Add pages to editor platform, init configs, etc);
      console.log('App was installed ✅');
      fedopsLogger.interactionEnded('install');
    }
  } catch (e) {
    reportError(e);
  }

  fedopsLogger.appLoaded();
};

export const getAppManifest = () => {
  return ({
    appDescriptor: {
      mainActions: [
        {
          title: t("App_Manager_Create_Album_CTA"),
          icon: "appManager_addElementsAction",
          actionId: CREATE_ALBUM_ACTION,
        },
        {
          title: t("App_Manager_Manage_Albums_CTA"),
          icon: "appManager_settingsAction",
          actionId: MANAGE_ALBUMS_ACTION,
        },
      ],
      customActions: [
        {
          title: t("App_Manager_Manage_Albums_Store_CTA"),
          actionId: MANAGE_ALBUMS_STORE_ACTION,
          icon: "appManager_settingsAction",
          type: "dashboard",
        },
        {
          title: t("App_Manager_Customize_Albums_Page_CTA"),
          actionId: CUSTOMIZE_ALBUMS_PAGE_ACTION,
          icon: "appManager_pagesAction",
          type: "editorActions",
        },
      ],
      defaultActions: !hasBusinessPackage ? {
        upgrade: {
          upgradeType: 'SITE_UPGRADE',
          upgradeText: t("App_Manager_Upgrade_Banner_Text"),
          upgradeLinkText: t("App_Manager_Upgrade_Banner_Link_Text")
        },
        learnMoreKB: "3382633d-8c8c-4b05-bc10-009021ecf554",
      } : {
          learnMoreKB: "3382633d-8c8c-4b05-bc10-009021ecf554",
        },
    },
  })
};

const getComponentRef = async (applicationId: number) => {
  const comps = (await sdk.tpa.app.getAllCompsByApplicationId(appDefinitionId, applicationId)) || [];
  const albumsSectionComponent = comps[0] || {};
  return sdk.components.getById(appDefinitionId, { id: albumsSectionComponent.id });
};

const openAppSettings = async () => {
  const appData: any = await sdk.tpa.app.getDataByAppDefId(appDefinitionId, appDefinitionId);
  const componentRef = await getComponentRef(appData.applicationId);
  const photoAlbumsPage = await sdk.components.getPage(appDefinitionId, { componentRef });
  await sdk.document.pages.navigateTo(appDefinitionId, { pageRef: photoAlbumsPage });
  return sdk.editor.openComponentPanel(appDefinitionId, {
    url: appData.settingsUrl + `?instance=${instance}&applicationId=${appData.applicationId}&compId=${componentRef.id}`,
    type: sdk.editor.PanelType.Settings,
    componentRef,
    width: 404,
    height: 528,
    title: 'Photo Albums',
  });
};

export const onEvent = async ({ eventType, eventPayload }: any) => {
  if (eventType === 'appActionClicked') {
    switch (eventPayload.actionId) {
      case CREATE_ALBUM_ACTION:
        sdk.editor.openDashboardPanel(appDefinitionId, { url: BM_CREATE_ALBUM, closeOtherPanels: true });
        break;
      case MANAGE_ALBUMS_ACTION:
        sdk.editor.openDashboardPanel(appDefinitionId, { url: BM, closeOtherPanels: true });
        break;
      case MANAGE_ALBUMS_STORE_ACTION:
        sdk.editor.openDashboardPanel(appDefinitionId, { url: BM_MANAGE_ALBUMS_STORE, closeOtherPanels: true });
        break;
      case CUSTOMIZE_ALBUMS_PAGE_ACTION:
        await openAppSettings();
        break;
      default:
        break;
    }
  }
}
